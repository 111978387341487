module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-social9-socialshare/gatsby-browser.js'),
      options: {"plugins":[],"content":"4a9377270d3847c7bdd34e28c6fa32ce","async":true,"defer":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
